// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");


import "bootstrap";
import "../stylesheets/application";
import "@fortawesome/fontawesome-free/css/all";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
import AOS from 'aos';
import 'aos/dist/aos.css';



document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
  AOS.init();




  // Uncomment to copy all static images under ../images to the output folder and reference
  // them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
  // or the `imagePath` JavaScript helper below.
  //
  // const images = require.context('../images', true)
  // const imagePath = (name) => images(name, true)

  // carousel for the 5 tips
  $(document).ready(function () {
    $(".owl-carousel").owlCarousel({
      loop: true,
      margin: 10,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: true,
          loop: true,
          startPosition: 1,
        },
        675: {
          items: 2,
          nav: false,
          dots: true,
          loop: true,
          startPosition: 1,
        },
        1200: {
          items: 3,
          nav: false,
          loop: true,
          dots: true,
          startPosition: 5,
        },
      },
    });
  });

  // Uncomment to copy all static images under ../images to the output folder and reference
  // them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
  // or the `imagePath` JavaScript helper below.
  //
  // const images = require.context('../images', true)
  // const imagePath = (name) => images(name, true)


  //to the top button
  $(window).scroll(function () {
    var height = $(window).scrollTop();
    if (height > 60) {
      $('#top').fadeIn();
    } else {
      $('#top').fadeOut();
    }
  });
  $(document).ready(function () {
    $("#top").click(function (event) {
      event.preventDefault();
      $("html, body").animate({
        scrollTop: 0
      }, "slow");
      return false;
    });
  });
});
